import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { AXIOS_INSTANCE_ODS } from 'utilities/db';
import { Info } from 'react-feather';
import { useStore } from 'store';

export default function Payments() {
  const navigate = useNavigate();

  const { branch } = useStore((state) => state);

  const branchId = branch?.id;

  const [paymentAccount, setPaymentAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const accountInfo = paymentAccount?.responsePaymentService;

  const getPaymentAccount = async () => {
    try {
      setIsLoading(true);
      const response = await AXIOS_INSTANCE_ODS.post(
        `/merchant/getAccountInformation`,
        {
          external_id: `${branchId}`,
          level: 'BRANCH'
        }
      );
      const data = await response.data?.result;
      setPaymentAccount(data);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPaymentAccount();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <RenderIf
        isTrue={
          !paymentAccount ||
          !accountInfo?.identityInfo ||
          !paymentAccount?.existingMerchant?.bank_account_info
        }
      >
        <div className={styles.infoContainer}>
          <p className={styles.infoText}>
            Looks like you haven't setup a Payment Account yet.
          </p>
        </div>
        <div className={styles.body}>
          <p>
            To receive online payments you need to setup a Payment Account.{' '}
            <a
              onClick={() => {
                navigate(`/payments/create`);
              }}
            >
              Click here to setup your Payment Account.
            </a>
          </p>
        </div>
      </RenderIf>

      <RenderIf
        isTrue={
          accountInfo?.identityInfo &&
          accountInfo?.merchantInfo &&
          accountInfo?.bankAcountInfo
        }
      >
        <div className={styles.cardList}>
          <div className={styles.cardBx}>
            <header className={styles.header}>
              <div className={styles.icon}>
                {accountInfo?.bankAcountInfo?.currency}
              </div>
              <div className={styles.div}>
                <div className={styles.content}>
                  <p className={styles.p}>{accountInfo?.createdAt}</p>
                  <h5 className={styles.h5}>
                    {accountInfo?.identityInfo?.entity?.business_name}
                  </h5>
                  <h6 className={styles.h6}>
                    {accountInfo?.identityInfo?.entity?.email}
                  </h6>
                </div>
                <div>
                  <div
                    className={styles.badge}
                    style={{
                      background:
                        accountInfo?.onboardingStatus === 'APPROVED'
                          ? 'var(--primary)'
                          : 'red'
                    }}
                  >
                    {accountInfo?.onboardingStatus?.toLowerCase()}
                  </div>
                  <RenderIf
                    isTrue={accountInfo?.onboardingStatus !== 'APPROVED'}
                  >
                    <div>See status</div>
                  </RenderIf>
                </div>
              </div>
            </header>
            <div className={styles.cardBody}>
              <div>
                <p className={styles.p}>Phone:</p>
                <h6 className={styles.h6}>
                  {accountInfo?.identityInfo?.entity?.business_phone}
                </h6>
              </div>
              <div className={styles.textRight}>
                <p className={styles.p}>Max transaction:</p>
                <h6 className={styles.h6}>
                  {accountInfo?.identityInfo?.entity?.max_transaction_amount}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </RenderIf>
    </div>
  );
}
